import React from 'react';
import { FaPlane, FaClock, FaPlaneArrival, FaHotel } from 'react-icons/fa';

function InternationalGuests() {
  return (
    <div className="info-wrapper">
      <h2>International Guests</h2>
      <div className="info-item">
        <span className="icon"><FaPlane /></span>
        Flights are the cheapest 7-10 months in advance
      </div>
      <div className="info-item">
        <span className="icon"><FaPlane /></span>
        Philippine Airlines has direct flights from Seattle to Manila<br/>(3 days a week)
      </div>
      <div className="info-item">
        <span className="icon"><FaClock /></span>
        The Philippines is 16hrs ahead of Pacific Standard Time
      </div>
      <h3>Recommended Airlines</h3>
      <div className="info-item">
        <span className="icon"><FaPlaneArrival /></span>
        Philippine Airlines (nonstop from Seattle)
      </div>
      <div className="info-item">
        <span className="icon"><FaPlaneArrival /></span>
        ANA (layover in Narita, Japan)
      </div>
      <div className="info-item">
        <span className="icon"><FaPlaneArrival /></span>
        Eva Air (layover in Taipei)
      </div>
      <h2 className='accommodations-title'>Accommodations</h2>
      <div className="info-item hotel-accommodation">
        <span className="icon"><FaHotel /></span>
        There will be hotel blocks in Manila before the wedding and in Alfonso.<br/>When available, we'll send out an update.
      </div>
    </div>
  );
}

export default InternationalGuests;