import React from 'react';

function Footer() {
  return (
    <footer className="footer">
      <p>Cheryl & Ross<br/>Wedding June 28, 2025</p>
    </footer>
  );
}

export default Footer;