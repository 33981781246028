import React, { useState, useEffect, useRef } from 'react';
import coupleImage from '../assets/images/save-the-date-condensed.png';
import { FilloutStandardEmbed } from "@fillout/react";
import "@fillout/react/style.css";

function RSVPSection() {
  const [isFlipped, setIsFlipped] = useState(false);
  const [hasRSVPed, setHasRSVPed] = useState(false);
  const formContainerRef = useRef(null);

  const handleRSVP = () => {
    setIsFlipped(true);
  };

  const handleRSVPDone = () => {
    setIsFlipped(false);
    setHasRSVPed(true);
  };

  useEffect(() => {
    const resizeForm = () => {
      if (formContainerRef.current) {
        const width = formContainerRef.current.offsetWidth;
        const formIframe = formContainerRef.current.querySelector('iframe');
        if (formIframe) {
          formIframe.style.width = `${width}px`;
        }
      }
    };

    resizeForm();
    window.addEventListener('resize', resizeForm);

    return () => {
      window.removeEventListener('resize', resizeForm);
    };
  }, [isFlipped]);

  return (
    <div className={`rsvp-wrapper ${isFlipped ? 'flipped' : ''}`}>
      <div className={`rsvp-front ${isFlipped ? 'fade-out' : 'fade-in'}`}>
        <div className="rsvp-content">
          <img src={coupleImage} alt="Cheryl & Ross" className="couple-image" />
          {hasRSVPed ? (
            <p className="rsvp-thank-you">Thank you for RSVP'ing.<br/>We're looking forward to seeing those that can make it!</p>
          ) : (
            <>
              <button className="rsvp-button" onClick={handleRSVP}>RSVP</button>
              <p className="rsvp-deadline">Please RSVP by November 29, 2024</p>
            </>
          )}
        </div>
      </div>
      <div className={`rsvp-back ${isFlipped ? 'fade-in' : 'fade-out'}`}>
        <div className="form-container" ref={formContainerRef}>
          <FilloutStandardEmbed 
            filloutId="g43rz7XhUcus"
            dynamicResize
          />
        </div>
        <div className="done-button-container">
          <p className="form-instruction">Make sure to press the submit button at the end of the form!</p>
          <button className="rsvp-done-button" onClick={handleRSVPDone}>Done</button>
        </div>
      </div>
    </div>
  );
}

export default RSVPSection;