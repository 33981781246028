import React from 'react';

function WhatToExpect() {
  return (
    <div className="page-wrapper">
      <div className="content-wrapper">
        <h2>What To Expect</h2>
        <img className="full-width-image" src="https://example.com/placeholder-image.jpg" alt="What to expect" />
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam tellus lectus, imperdiet nec semper vel, mattis vitae elit. Vestibulum eget arcu condimentum, eleifend turpis in, gravida felis. Ut sit amet ex quis orci pretium vehicula.</p>
        <p>In vel augue sodales, finibus nisl non, sollicitudin felis. Fusce non mauris vulputate, blandit tortor quis, porta tellus. Suspendisse nisi elit, semper sit amet est vel, ornare finibus quam. Maecenas in dignissim enim. Nullam quis sollicitudin ligula, ut commodo diam. Morbi sed nibh aliquet, bibendum orci ac, vulputate ex.</p>
      </div>
    </div>
  );
}

export default WhatToExpect;