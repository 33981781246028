import React from 'react';

function OurStory() {
  return (
    <div className="page-wrapper">
      <div className="content-wrapper">
        <h2>Our Story</h2>
        <img className="full-width-image" src="https://example.com/placeholder-image.jpg" alt="Our story" />
        
        <h3>The Homie Connect</h3>
        <p>In 2021, Cheryl's deep love and work within the community led her to embark on a new venture of opening up a multi-purpose event space in South Park Seattle. As we all know, Cheryl is highly ambitious and entrepreneurial, so she was looking for collaborators who could go with her on this vision and turn something that may look like a junkyard full of overgrown ivy and a worn down warehouse - to a creative collaborative space for community and culture to thrive. She had all the pieces together except one, she needed a technical collaborator who had specific experience in AV who could turn something that feels impossible into reality— and that is when the stars aligned and her friend Canh introduced her to Ross.</p>

        <h3>The first meeting → all business no pleasure</h3>
        <p>At their first meeting, both were punctual, with laptops out and ready to work. As Cheryl outlined the complex and ambitious project she was undertaking, Ross listened intently, taking notes and asking clarifying questions. He also made a point of "putting a pin" on topics to revisit later, aiming to maximize the meeting's efficiency and productivity. They spoke openly about capacity and budget—topics that many people often avoid or feel awkward discussing in a first meeting—but it was refreshing for both of them to be so transparent and straightforward, with no pretense. They ended the meeting right on time and agreed to collaborate on the project. Ross followed up with a well-organized document containing meeting notes, action items, key dates, and his promised deliverables—A standard that Cheryl always held for herself, but had never experienced with anyone else.</p>

        <h3>The unexpected spark</h3>
        <p>A week before both of their events, they decided to do a quick check-in at Can Bar in White Center for a 45 minute meeting. Cheryl almost canceled because she had worked every day that week, but told herself that she would go just for one drink and take Cheech with her. They sat in the back patio, talking about how stressed they were and how they wished people could read their emails and just follow through with what they signed up for. One drink led to dinner, which then led to Cheryl inviting Ross over to her place for a glass of wine and a chance to see the progress of her project. Ross was pleasantly surprised to see that Cheryl was also a natural wine drinker and that turned into having a few glasses at her apartment. When they finally looked at the time, 5+ hours had gone by and it was time to say goodnight.</p>
        <p>After their "check-in/ work update meeting", Cheryl and Ross were inseparable. They had a meal together almost every day leading up to their events – doing work parties and staying up late helping each other out with physical labor that was required for both of their events.</p>

        <h3>"Talking to each other" to "wanna date?"</h3>
        <p>After their events, they had long hangouts out in West Seattle (where they both grew up), drank natural wine on the beach, and even decided to follow through on their "platonic" camping trip. It wasn't until a month into "hanging out" almost everyday that Ross asked Cheryl out on a formal date. Initially she said "she would think about it", but eventually caved and agreed to it. Ross took her to an all men's professional pool table club. Although she was the only woman there and there were only men playing pool by themselves in silence, it didn't stop her from making a potentially awkward situation into a deep/ soul filled/ vulnerable connection. They then ended the night with Cheryl taking Ross through her test of "will this white boy eat the weirdest thing on the menu" challenge …and he passed!</p>

        <h3>Three years of adventure</h3>
        <p>The sky's the limit with these two creative and ambitious innovators. They ha</p>

        <h3>The proposal</h3>
        <p>[Content for the proposal section is not provided]</p>
      </div>
    </div>
  );
}

export default OurStory;