import React from 'react';
import { FaPlane, FaBus, FaGlassCheers, FaUmbrellaBeach } from 'react-icons/fa';

function Schedule() {
  return (
    <div className="schedule-wrapper" id="schedule">
      <h2>Schedule</h2>
      <div className="schedule-item">
        <span className="icon"><FaPlane /></span>
        <span><span className="date">June 25 - 26, 2025</span> - All International Guests Arrive in Manila</span>
      </div>
      <div className="schedule-item">
        <span className="icon"><FaBus /></span>
        <span><span className="date">June 27, 2025</span> - Shuttles leave Manila to Alfonso (all guests welcome)</span>
      </div>
      <div className="schedule-item">
        <span className="icon"><FaGlassCheers /></span>
        <span><span className="date">June 28, 2025</span> - Ceremony & Reception</span>
      </div>
      <div className="schedule-item">
        <span className="icon"><FaUmbrellaBeach /></span>
        <span><span className="date">June 30 - July 3, 2025</span> - Buddymoon (friends and family island vacation)</span>
      </div>
      <div className="schedule-item">
        <span className="icon"><FaPlane /></span>
        <span><span className="date">July 4 - 6, 2025</span> - Fly home or continue travels</span>
      </div>
    </div>
  );
}

export default Schedule;