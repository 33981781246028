import React from 'react';
import RSVPSection from '../components/RSVPSection';
import Schedule from '../components/Schedule';
import InternationalGuests from '../components/InternationalGuests';

function Home() {
  return (
    <div className="content-wrapper">
      <RSVPSection />
      <Schedule />
      <InternationalGuests />

      <div className="update-info">
        <h2>Updates Coming Soon</h2>
        <p>
          We'll be updating this website over the next few months. Expect all of the information, wedding party, and more details to be added by the end of January.
        </p>
        <p>
          For any questions do not hesitate to reach out to Ross at <a href="mailto:rossmonroe@gmail.com">rossmonroe@gmail.com</a>.
        </p>
      </div>
    </div>
  );
}

export default Home;